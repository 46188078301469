<template>
  <div class="bg-image" style="background-image: url('/assets/img/ar-bg.png');">
    <div class="container" style="max-width: 1261px;">
      <div class="panl-title">
    <img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" />
    <router-link :to="'/media?lang='+$i18n.locale" style="text-decoration: none !important;">
        <div class="tl panl-title-text d-inline">{{ $t("zaer_services") }}</div>
    </router-link>
</div>


      <div class="row mt-4">
        <div class="col-md-2 border-end">
    <ul class="nav flex-column nav-pills">
        <li class="nav-item mb-2">
            <button class="nav-link icon-before" :class="{ active: activeTab === 1 }" @click="selectTab(1)">{{ $t("index_zyara") }}</button>
        </li>
        <li class="nav-item mb-2">
            <button class="nav-link icon-before" :class="{ active: activeTab === 2 }" @click="selectTab(2)">{{ $t("msg_message_to_shrine") }}</button>
        </li>
        <li class="nav-item mb-2">
            <button class="nav-link icon-before" :class="{ active: activeTab === 3 }" @click="selectTab(3)">{{ $t("relig_questions") }}</button>
        </li>
        <li class="nav-item mb-2">
            <button class="nav-link icon-before" :class="{ active: activeTab === 4 }" @click="selectTab(4)">{{ $t("index_lost") }}</button>
        </li>
    </ul>

    <div class="d-none d-md-block">
        <hr class="mt-4 mb-1">

        <small class="text-center">خدمات إضافية</small>
        <ul class="nav-pills mt-2">
            <li><a :href="'https://alkafeel.net/panorama/?lang='+$i18n.locale" target="_blank" class="text-decoration-none  icon-before-link">{{ $t("virtual_reality") }}</a></li>
            <!-- <li><a href="#" class="text-decoration-none  icon-before-link">إدارة الفعاليات</a></li> -->
            <li><a :href="'https://alkafeel.net/muharram/?lang='+$i18n.locale+'#visitor'" target="_blank" class="text-decoration-none  icon-before-link"> {{ $t('visitor_guide') }} </a></li>
            <li><a :href="'https://alkafeel.net/comment/?lang='+$i18n.locale" target="_blank" class="text-decoration-none  icon-before-link"> {{ $t('index_guestbook') }}</a></li>
            <li><a :href="'https://alkafeel.net/lib/?lang='+$i18n.locale" target="_blank" class="text-decoration-none  icon-before-link">{{ $t('pub') }}</a></li>
        </ul>
    </div>
</div>


         <div class="col-md-7">
    <div class="mx-5" v-if="activeTab === 1"> 
        <h4 class="mb-4">
            <a :href="'https://alkafeel.net/zyara/?lang='+$i18n.locale" target="_blank" class="text-decoration-none service_title">{{ $t("index_zyara") }}</a>
        </h4>
        <h4 class="mb-4 service_detail">{{ zyarares.title }}</h4>
        <v-form ref="form">
            <v-text-field @keyup.enter.native="zeara_send()" v-model="zeara_i.ab" :label="$t('zyara_ab')" outlined required ></v-text-field>
            <v-text-field v-model="zeara_i.email" :label="$t('msg_email_optional')" @keyup.enter.native="zeara_send()" outlined type="email" :rules="[rules.email]" ></v-text-field>
            <v-btn class="k-color" @click="zeara_send()">{{ $t('action_send') }}</v-btn>
        </v-form>
    </div>
    
    <div class="mx-5" v-if="activeTab === 2">
        <h4 class="mb-4">
            <a :href="'https://alkafeel.net/messages/?lang='+$i18n.locale" target="_blank" class="text-decoration-none service_title">{{ $t("msg_message_to_shrine") }}</a>
        </h4>
        <p class="service_detail">{{ $t("msg_message_to_shrine_text_index") }}
        </p>
        <v-form ref="form">
          <v-text-field @keyup.enter.native="message_send()" v-model="message_i.name" :label="$t('name')" outlined :rules="[rules.required]" ></v-text-field>
          <v-text-field @keyup.enter.native="message_send()" v-model="message_i.email" :label="$t('msg_email_optional')" outlined type="email" :rules="[rules.email]" ></v-text-field>
          <v-text-field @keyup.enter.native="message_send()" v-model="message_i.subject" :label="$t('contact_subject')" outlined :rules="[rules.required]" ></v-text-field>
          <v-textarea v-model="message_i.message" :label="$t('msg_contents')" outlined :rules="[rules.required]" ></v-textarea>
          <v-btn @click="message_send()" class="k-color">{{ $t('action_send') }}</v-btn>
        </v-form>

    </div>

    <div class="mx-5" v-if="activeTab === 3">
        <h4 class="mb-4">
            <a href="https://alkafeel.net/religious/" target="_blank" class="text-decoration-none service_title">{{ $t("relig_questions") }}</a>
        </h4>
        <v-form ref="form">
          <v-text-field dense @keyup.enter.native="question_send()" v-model="questions_i.name" :label="$t('name')" outlined :rules="[rules.required]" ></v-text-field>
          <v-text-field @keyup.enter.native="question_send()" v-model="questions_i.phone" :label="$t('Phone_Num_optional')" outlined ></v-text-field>
          <v-text-field @keyup.enter.native="question_send()" v-model="questions_i.email" :label="$t('email')" :rules="[rules.required,rules.email]" outlined type="email" ></v-text-field>
          <v-textarea v-model="questions_i.quest" :label="$t('question_text')" :rules="[rules.required]" outlined required ></v-textarea>
          <v-btn @click="question_send()" class="k-color">{{ $t('action_send') }}</v-btn>
        </v-form>

    </div>

    <div v-if="activeTab === 4">
        <h4 class="mb-4">
            <a href="https://alkafeel.net/lost/" target="_blank" class="text-decoration-none service_title">{{ $t("index_lost") }}</a>
        </h4>
        <v-form class="lost-file" ref="form">
            <v-text-field :rules="[rules.required,rules.min]" v-model="i.search" :label="$t('search_losts')"  outlined prepend-icon="mdi-magnify" @keyup.enter.native="lost_send()"></v-text-field>
            <v-btn @click="lost_send()"  class="mx-2 my-3 cu-s-cearch k-color">{{ $t('action_search') }} </v-btn>
        </v-form>
        
        <div class="lostNores_item tl" v-if="lostNores">{{ $t("msg_no_result") }}</div>
        <table class="table striped-table" v-if="lostNoresTitem">
            <thead>
              <tr>
                <th class="table-header">{{ $t('name') }}</th>
                <th class="table-header">{{ $t('lost_doc') }}</th>
                <th class="table-header">{{ $t('L_city') }}</th>
                <th class="table-header">{{ $t('lost_date') }}</th>
            </tr>
            </thead>
            <tbody>
              
                <tr v-for="i in lostres" :key="i">
                    <td class="lost-item-name">{{i.name}}</td>
                    <td> {{i.type}} </td>
                    <td>{{i.city}}</td>
                    <td>{{i.date}}</td>
                </tr>
            </tbody>
        </table>

        <v-btn href="https://alkafeel.net/lost/" target="_blank" class=" k-color  mx-2 my-3">{{ $t('show_m_results') }} </v-btn>
    </div>
</div>





<div class="col-md-3">
  <div class="slider sld">
    <swiper class="swiper swp dirr" ref="mySwiper" :options="swiperOptions5">
        <swiper-slide v-for="img in images" :key="img.id">
            <a :href="img.link + '?lang=' + $i18n.locale" target="_blank">
             
                <div class="p_con tl d-flex mb-3 icon-lost-link">
                    <img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" style="height: 16px; align-items: center; margin: 0 6px;"/> 
                    {{ $t(img.title) }}
                </div> 
                <img :src="img.src" :alt="img.title" class="lost-img"/>
            </a>
        </swiper-slide>
    </swiper>
</div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      activeTab: 1,
      zyarares:[],
      lostres:[],
      lostNores: true,
      lostNoresTitem: false,
      swiperOptions5: {
          slidesPerView: "auto",
          spaceBetween:40,
          navigation: {
              nextEl: ".rbtn2",
              prevEl: ".lbtn2",
          },
          keyboard: true,
      },
      images: [
        { id: 1, link: 'https://alkafeel.net/panorama/', src: 'https://alkafeel.net/src/images/Panorama.jpg', title: 'virtual_reality' }
      ],
      i: {
          search: '',
      },
      zeara_i: {
          name: '',
          email: '',
          ab: '',
          save: 'save'
      },
      questions_i: {
          name: '',
          email: '',
          quest: '',
          phone: ''
      },
      message_i: {
          name: '',
          email: '',
          subject: '',
          message: ''
      },
      wait: 10, // in minutes
      rules: {
        required: value => !!value || this.$t("required_field"),
        min: (v) => v.length >= 3 || this.$t("three_letters"),
        email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t("alert_wrong_email"),
      },

    };
  },
  methods: {
    selectTab(tab) {
      this.activeTab = tab;
    },
    ini() {
      this.$axios.get("zyara/GetDetails", {})
      .then(res => {
          this.zyarares = res.data.Data;
      });

      this.$axios.get("losts/searchlimit", {})
      .then(res => {
          this.lostres = res.data;
          if (res.data == "") {
              this.lostNores = true;
              this.lostNoresTitem = false;
          } else {
              this.lostNores = false;
              this.lostNoresTitem = true;
          }
      })
    },

    lost_send() {
      if (this.$refs.form.validate()) {
          this.loader = 'loading';
          this.$axios.get('losts/search/' + this.i.search, {}).then(res => {
              this.lostres = res.data;
              if (res.data == "") {
                  this.lostNores = true;
                  this.lostNoresTitem = false;
              } else {
                  this.lostNores = false;
                  this.lostNoresTitem = true;
              }
          }).catch(() => {});
      }
    },

    zeara_send() {
            if (this.$refs.form.validate()) {
                var formdata = new FormData();
                //   formdata.append("name",  this.zeara_i.name);
                formdata.append("ab", this.zeara_i.ab);
                formdata.append("save", "save");
                formdata.append("email", this.zeara_i.email);
                this.loader = 'loading';
                this.$axios({
                    method: 'post',
                    url: 'https://alkafeel.net/zyara/Avdasdw/1352897989/post',
                    data: formdata,
                }).then(
                    this.zeara_i = {},
                    Swal.fire({

                        position: 'center',
                        icon: 'success',
                        title: this.$t("zeara_result"),
                        showConfirmButton: false,
                        timer: 2000

                    })
                    //console.log(res.data);
                ).catch(() => {});
            }
        },
        question_send() {
            if (this.$refs.form.validate()) {
                this.loader = 'loading';
                this.$axios.post('religious/sendQuest', {
                    name: this.questions_i.name,
                    phone: this.questions_i.phone,
                    email: this.questions_i.email,
                    quest: this.questions_i.quest,
                }).then(
                    Swal.fire({


                        position: 'center',
                        icon: 'success',
                        title: this.$t("sending_done"),
                        showConfirmButton: false,
                        timer: 2000

                    }),
                    this.questions_i = {}
                ).catch(() => {});
            }
        },
        message_send() {
          if (this.$refs.form.validate()) {
              this.loader = 'loading';
              var query;
              if (this.message_i.email == '') {
                  query = {
                      name: this.message_i.name,
                      subject: this.message_i.subject,
                      message: this.message_i.message,
                  }
              } else {
                  query = this.message_i
              }




              if (this.checkCookie('message_send', 'DMI')) {
                  Swal.fire({
                      title: this.$t("messege_wait")+' '+this.wait+' '+this.$t("time_mins"),
                      text: "",
                      icon: "warning",
                      timer: 3000,
                      confirmButtonColor: 'red',
                  });
              } else {
                  this.$axios.post('alkafeelMessage/store', query).then((res) => {
                      if (res.status == 200) {
                          Swal.fire({
                              title:  '<strong>'+this.$t('msg_message_sent')+'</strong>',
                              html: this.$t('msg_use_code_to') +  '  &nbsp <h2>' + res.data.code+'</h2>',
                              icon: "success",
                              confirmButtonColor: '#B1BD52', 
                              confirmButtonText: this.$t('msg_copy_code'),
                              preConfirm: () => {
                          
                              const el = document.createElement('textarea');
                              el.value = res.data.code;
                              document.body.appendChild(el);
                              el.select();
                              document.execCommand('copy');
                              document.body.removeChild(el);
                              }
                          });
                          this.message_i = {};

                          this.setCookie('message_send', 'DMI');
                      }
                      else {
                          Swal.fire({
                              title: this.$t("alert_send_error"),
                              text: "",
                              icon: "error",
                              timer: 3000,
                              confirmButtonColor: 'red',
                          });
                      }
                  }
                  ).catch(() => {
                      Swal.fire({
                          title: this.$t("alert_send_error"),
                          text: "",
                          icon: "error",
                          timer: 3000,
                          confirmButtonColor: 'red',
                      });
                  });
              }
          }
        },

        setCookie(cname, cvalue) {
            const d = new Date();
            d.setTime(d.getTime() + (this.wait*60*1000));
            let expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
        getCookie(cname) {
            let name = cname + "=";
            let ca = document.cookie.split(';');
            for(let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        checkCookie(cname, id) {
            let username = this.getCookie(cname);
            if (username != "") {
                if (id === username) {
                    return true
                } else {
                    return false
                }
            }
        },
  },
  mounted() {
    this.ini();
  }
};
</script>

<style scoped>
.service_title {
  color: #000;
}
.theme--dark .service_title {
  color: #fff;
}
.bg-image {
  background-size: cover;
  background-position: center;
  padding: 40px 0;
}

.icon-before-link{
  color: #303030;
}

.border-end {
  border-left: 1px solid #dee2e6;
}

.nav-link.active {
  font-weight: bold; 
  background-color: #dddddd;
}



.nav-link:hover {
  background-color: #f1f1f1; 
  padding: 0px 1px;
}

.cu-s-cearch {
  height: 56px !important;
  margin: 0 4px !important;
}

.more-link {
  color: #333333;
}



.icon-before::before {
  content: url('/assets/img/object.svg');
  display: inline-block;
  margin: 0 8px;
  vertical-align: middle;
}

.icon-before-link::before {
  content: url('/assets/img/external-link.svg');
  display: inline-block;
  margin: 0 8px;
  vertical-align: middle;
  width: 13px;
}

.nav-pills {
  list-style: none;
}

.nav-pills li {
  margin: 12px 0;
}

.nav-pills a:hover {
  font-weight: bold; 
  /* text-decoration:underline !important; */

}

.table {
    width: 100%;
    border-collapse: collapse;
    text-align: right;
}

.table-header {
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    min-width: 100px;
  }

.lost-item-name {
    padding: 10px;
}


.lost-item-name::before {
  content: url('/assets/img/object.svg');
  display: inline-block;  
  width: 13px;
  margin: 0 8px;

}

.lost-file {
  display: flex;
  align-items: flex-start;
  
}

.k-color {
  color: white;
  background: #B1BD52 !important;
}

.lost-img {
  height: 400px;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.icon-lost-link{
    color: #333333;
    text-decoration: none;
    align-items: center;
  }

   a{
    text-decoration: none;
   }

.table {
    width: 100%;
    border-collapse: collapse;
}

.table-header, .lost-item-name, td {
    /* border: 1px solid #dee2e6; */
    padding: 8px;
}

@media (max-width: 767px) {
    .table thead th:nth-child(n+3),
    .table tbody td:nth-child(n+3) {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .table thead th:nth-child(4),
    .table tbody td:nth-child(4) {
        display: none;
    }
}

.striped-table tbody tr:nth-child(odd) {
    background-color: #f2f2f2; 
}



@media (min-width: 768px) and (max-width: 1100px) {
  .icon-before {
  font-size: 14px;
}
}

.slider{
    width: 100% !important;
}
</style>
